<template>
  <div class="filter-music">
      <div v-for="(obj, key) in dataList" :key="key">
        <div class="item-title" @click="switchCate(key)">
          <h3>{{obj.name}}</h3>
          <img src="@/assets/images/web/music/caret_up.svg" class="item-tab-down" v-if="checked_idx.indexOf(key)!='-1'"/>    
          <img src="@/assets/images/web/music/caret_down.svg" class="item-tab-down" v-if="checked_idx.indexOf(key)=='-1'"/>
        </div>
        <div class="item-content" v-if="checked_idx.indexOf(key)=='-1'" >
            <span v-for="(obj2, key2) in obj.child" :key="key2" @click="onClickLables(obj2)" class="filter_span" :checked="selecteLabelIds.indexOf(obj2.label_id) != '-1' ">
              <span>{{obj2.name}}</span>
            </span>
        </div>
      </div>

      <div v-if="isloading">
        <div class="item-title">
          <h3>BPM</h3>
        </div>
        <div class="item-content" style="display:block; width: 90%;">
          <a-slider
              :defaultValue="[bpmStart, bpmEnd]"
              :min="bpmRange[0]" :max="bpmRange[1]" 
              range
              @change="changebpmSlider"
            />
            <a-input-number class="input-number" v-model="bpmStart" disabled="disabled" />
            <span class="input-str"> - </span>
            <a-input-number class="input-number" v-model="bpmEnd" disabled="disabled"/> 
        </div>
      </div>

       <div v-if="isloading">
        <div class="item-title">
          <h3>时长</h3>
        </div>
        <div class="item-content" style="display:block; width: 90%;">
          <a-slider
              range
              :defaultValue="[durationStart, durationEnd]"
              :min="durationRange[0]" :max="durationRange[1]" 
              :tipFormatter="(value) => formatTimeBySecond(value)"
              @change="changeSlider"
            />
            <a-input-number class="input-number" v-model="durationStart" :formatter="(value) => formatTimeBySecond(value)" disabled="disabled" />
            <span> - </span>
            <a-input-number class="input-number" v-model="durationEnd" :formatter="(value) => formatTimeBySecond(value)" disabled="disabled" /> 
        </div>
      </div>

  </div>
</template>

<script>
  import {InputNumber} from 'ant-design-vue';
  import {formatTimeBySecond} from '@/utils/function'
  export default {
    data() {
      return {
        checked_idx: [],
        song_idx: 0, //全部
        durationRange: this.$config.MusicSearchDurationRange,
        durationStart: this.$config.MusicSearchDurationRange[0],
        durationEnd: this.$config.MusicSearchDurationRange[1],
        bpmRange: this.$config.MusicSearchBpmRange,
        bpmStart: this.$config.MusicSearchBpmRange[0],
        bpmEnd: this.$config.MusicSearchBpmRange[1],
        bpmSlider:[],
        durationSlider:[],
        selecteSongIds:[],
        isloading: true,
      }
    },
    props: {
      dataList: {
        type: Array
      },
      selecteLabelIds:{
        type: Array
      },
    },
    components: {
      AInputNumber: InputNumber,
    },
    methods: {
      formatTimeBySecond,
      switchCate(key) {
        let idx = this.checked_idx.indexOf(key);
        if(idx != "-1") {
          this.checked_idx.splice(idx, 1);
        } else {
          this.checked_idx.push(key);
        }
      },
      changeSlider(changeValue){
        this.durationStart = changeValue[0];
        this.durationEnd = changeValue[1];
        this.durationSlider['min'] =  this.bpmStart;
        this.durationSlider['max'] =  this.bpmEnd;
        this.freshTable()
      },
      changebpmSlider(changeValue){
        this.bpmStart = changeValue[0];
        this.bpmEnd = changeValue[1];
        this.bpmSlider['min'] =  this.bpmStart;
        this.bpmSlider['max'] =  this.bpmEnd;
        this.freshTable()
      },
      onClickLables(item){
        this.$emit('labelsChange', item);
      },
      onClickSong(item){
        let idx = this.selecteSongIds.indexOf(item);
        if(idx != "-1") {
          this.selecteSongIds.splice(idx, 1);
        } else {
          this.selecteSongIds.push(item);
        }
        this.freshTable();
      },
      freshTable(){
        //刷新table
        let values = {
          label_ids: this.selecteLabelIds,
          bpm: this.bpmSlider,
          duration: this.durationSlider
        }
        this.$emit('labelsChange', values);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .filter-music {
    padding-left: 24px;
    padding-right: 24px;
    .item-title{
      cursor: pointer;
      margin-top: 18px;
      .item-tab-down{
        width: 16px;
        height:16px;
      }
    }
    h3{
      display: inline-block;
      width: 90%;
    }
    .item-content{
      .input-label{
        background: #000000;
        font-size: 32px;
      }
      .filter_span{
        display: inline-block;
        width: 46%;
        margin-left:2%;
        margin-bottom: 10px;
        background: #F2F4F5;
        border-radius: 4px;
        text-align:center;
        line-height:32px;
        cursor:pointer;
        &:hover{
          color:#fff;
          background:#FF7B13;
        }
      }
      .filter_span[checked]{
        color:#fff;
        background:#FF7B13;
      }
      
      .input-number{
        width: 58px;
        text-align: center;
        background: #F2F4F5;
        border-radius: 12px;
        opacity: 0.79;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
      }
      .input-str{
        height: 24px;
        line-height: 24px;
        margin:0;
        text-align: center;
      }
    }
    .item-content[disabled] {
      display: block;
    }
  }

@media only screen and (max-width: $screen-width-md) {
  h3{
    font-size: 14px;
  }
  .filter-music .item-content{
    span{
      font-size: 12px;
    }
    .input-number{
      //width: 30px;
    }
  }
}
</style>
