<template>
  <div class="container" style="margin-top:24px;">
    <a-button class="filter_open" v-if="filter_button" @click="changefilerDisplay(1)">筛选  <a-icon type="right" /></a-button>  
    <div class="filter_music" id="boxFixed" v-if="!isfixed" :style="filterStyle" >
      <div class="filter_head">
        <h3> <a-icon type="sliders" /> 筛选条件</h3>
        <p>
          <span style="font-size:12px;color:#FF7B13;font-weight:500;height:25px;" @click="clearSearch()" v-if="selecteLabels.length>0">清空筛选  </span>
          <a-icon type="left" style="font-size:18px;" @click="changefilerDisplay(0);"/>
        </p>
      </div>
      <filter-music @labelsChange="onChangeLabels" :dataList="allLabels" :selecteLabelIds="selecteLabelIds" :style="filterWrapStyle" class="filter_music_wrap"></filter-music>
    </div>
    <div class="filter_table_content" :style="tableStyle">
      <div class="table_info" style="display:block;width:100%;margin-bottom:32px;">
       <a-row>
          <a-col :xs="12" :sm="12" :md="6" :xl="4">
            <img :src="supplierInfo.profile_photo_url" style="width:180px;height:180px;">
          </a-col>
          <a-col :xs="12" :sm="12" :md="16" :xl="16" style="line-height:20px;">
            <b class="font-heading-1 m-bottom-sm block">{{$root.getShowName(supplierInfo)}}</b>
            <p>
              <img src="@/assets/images/web/position.svg"/>
              <span>  {{supplierInfo.address}}</span>
            </p>
            <div class="m-bottom-lg">{{supplierInfo.desc}}</div>
          </a-col>
        </a-row>
      </div>
      <div class="table_head">
        <a-row type="flex" justify="center" align="top">
          <a-col :xs="10" :sm="10" :md="10" :lg="10" :xl="16" style="text-align:left;">
            <span class="head_total">  排序方式：  </span>
            <span class="sort_tag" :selected="searchType==2&&search_sel==1" @click="changeSearchType(2)">
              综合排序
            </span>
            <span> | </span>
            <span class="sort_tag" :selected="searchType==1&&search_sel==1"  @click="changeSearchType(1)">
              热门推荐
            </span>
            <span> | </span>
            <span class="sort_tag" :selected="sort_type==5&&sort_sel==1" @click="changeSort(5)">
              最新上架
            </span>
          </a-col>
          <a-col :xs="14" :sm="14" :md="14" :lg="14" :xl="8"  style="text-align:right;padding-right:1%;">
            <span class="head_total">  类型： </span>
            <a-button class="type_button" :selected="selectSongidx==0" @click="changeSong('0')">全部</a-button>
            <a-button class="type_button" :selected="selectSongidx==1" @click="changeSong('1')">VIP专区</a-button>
            <a-button class="type_button" :selected="selectSongidx==2" @click="changeSong('2')">零售专区</a-button>
          </a-col>
        </a-row>
      </div>
      <music-table class="table_bottom" 
        id="boxTable"
        :request="this.$axios.Music_List" 
        :isImmediate="isImmediate" 
        :params="musicParams"  
        :is-more="false" 
        :pagination="pagination" 
        @labelsChange="onChangeLabels" 
        :selecteLabelIds="selecteLabelIds" >
      </music-table>
    </div>
  </div>
</template>

<script>
  import MusicTable from '@/pages/components/music/NewMusicTableAllMusic.vue'
  import FilterMusic from '@/pages/components/music/filter/NewFilterMusic.vue'
  import {Icon} from 'ant-design-vue'

  export default {
    data() {
      return {
        user_id: '',
        pagination: {
          "show_pagin" : true,
          "show-quick-jumper": true,
          "default-current":1,
          total:0,
          pageSize: 20,
          current: 0,
          onChange: this.onChangePage,
          hideOnSinglePage: true,
          style:{
            "float": "none",
            "text-align": "center",
          },
        },
        searchType: 2,
        sort_type: 11,  //排序方式
        time_up:0, //时间从上到下
        speed_up:0, //速度从上到下
        search_sel:1,
        sort_sel:0,
        totalNum: '',
        selecteLabels:[], //选中的标签信息
        defaultLabels: this.$route.query.labels || '',
        allLabels:[], //标签数据
        formatLabels:[], //标签数据format
        selecteLabelIds:[], //选中的标签ID api会用到
        isImmediate: true,
        selectSongidx: 0,
        supplierInfo:{},
        isfixed: true,
        tableStyle:"width:100%",
        filter_button: true,
        filterStyle: '',
        filterWrapStyle:'',
      }
    },
    created() {
      let params = this.$route.params;
      let user_id = params.user_id;
      this.user_id = user_id;
      this.setSearchParams(user_id);
      this.getLabels();
      this.getSupplierInfo({user_id: user_id});
    },
    components: {
      MusicTable,
      FilterMusic,
      AIcon:Icon,
    },
    mounted(){
      window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    },
    beforeDestroy(){
      window.removeEventListener("scroll",this.handleScroll);
    },
    methods: {
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
        let TableHeight = this.$el.querySelector(".filter_table_content");
        let clientHeight = TableHeight.scrollHeight;
        let newhel = clientHeight - scrollTop;
        this.filterStyle="height:"+newhel+"px;";
        this.filterWrapStyle = "height:"+(newhel-54)+"px;";
      },
      changefilerDisplay(idx){
        if(idx == 1){
          this.isfixed = false;
          this.filter_button = false;
          this.tableStyle = "margin-left:16%;width:84%;";
        } else {
          this.isfixed = true;
          this.filter_button = true;
          this.tableStyle = "margin-left:0;width:100%;";
        }
        this.handleScroll();
      },
      setSearchParams(user_id) {
        this.musicParams = Object.assign({}, this.musicParams, {user_id});
      },
      getSupplierInfo(params) {
        this.$axios.Sup_info(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.supplierInfo = data.data ? data.data : {};
          }
        })
      },
      getLabels() {
        this.$axios.CommonLabels({type: 1}).then(res => { //
          if (res.data.code == 0) {
            this.allLabels = res.data.data.list;
            for(let idx = 0; idx < this.allLabels.length; idx++) {
              let label_data = this.allLabels[idx].child;
              for(let child_idx = 0; child_idx <  label_data.length; child_idx++){
                this.formatLabels[label_data[child_idx].label_id] = label_data[child_idx];
              }
            }
            this.getDefaultLabels();
          }
        })
      },
      getDefaultLabels(){
        let labels = this.$route.query.labels || '';
        if(labels) {
          labels = labels.split(',');
          for(let idx = 0; idx < labels.length; idx++) {
            this.selecteLabels.push(this.formatLabels[labels[idx]]); //push才会同时改变视图
            this.selecteLabelIds.push(labels[idx]);
          }
          //刷新table
          let params = {
            label_ids: this.selecteLabelIds,
          }
          this.isImmediate = true;
          this.freshTable(params);
        }
      },
      freshTable(params){
        this.musicParams = Object.assign({}, this.musicParams, params);
        setTimeout(() => {
          this.handleScroll();
        }, 450);
      },
      changeSearchType(sort) {
        this.searchType = sort;
        this.search_sel = 1;
        this.sort_sel = 0;
        let params = {
          sort_type: sort
        }
        this.freshTable(params);
      }, 
      changeSort(sort){
        this.sort_sel = 1;
        this.search_sel = 0;
        if(sort == 0){
          if(this.speed_up != 0) {
            sort = 1; //从慢到快
            this.speed_up = 0;
          } else {
            this.speed_up = 1;
          }
        }
        if(sort == 3){
          //短到长 长到短
          if(this.time_up != 0) {
            sort = 4;
            this.time_up = 0;
          } else {
            this.time_up = 1;
          }
        }
        this.sort_type = sort;
        let params = {
          sort: sort
        }
        this.freshTable(params);
      },
      //切换曲库
      changeSong(idx){
        this.selectSongidx = idx;
        let params = {
          library_type: idx
        }
        this.freshTable(params);
      },
      clearSearch(){
        if(this.selecteLabels.length <= 0){
          return false;
        }
        this.selecteLabels = [];
        this.selecteLabelIds = [];
        let params = {
          label_ids: []
        }
        this.freshTable(params);
      },
      onChangeLabels(params) {
        let param = {};
        if(params.label_id){
          let idx = this.selecteLabelIds.indexOf(params.label_id);
          if(idx != "-1") {
            this.selecteLabelIds.splice(idx, 1);
            this.selecteLabels.splice(idx, 1);
          } else {
            if(this.selecteLabelIds.length < 6){
              this.selecteLabelIds.push(params.label_id);
              this.selecteLabels.push(params);
            }
          }
          param.label_ids = this.selecteLabelIds;
        }
        if(params.bpm){
          param.bpm = params.bpm;
        }
        if(params.duration){
          param.duration = params.duration;
        }
        this.freshTable(param);
      },
      onChangePage(params) {
        this.pagination.current = params;
        this.pagination = Object.assign({}, this.pagination);
        document.documentElement.scrollTop = 0;
      },
    }
  }
</script>

<style lang="scss" scoped>
.filter_open{
  position:fixed;
  width: 64px;
  height: 48px;
  left: 0px;
  top: 90px;
  color: #fff;
  font-size: 14px;
  background: #000;
  border-radius: 0 24px 24px 0;
  cursor: pointer;
  padding: 0 9px;
  opacity: 1;
  box-shadow: 0 0 0 1px #FF7B13;
  transition: all ease-in-out .2s;
}
.filter_music_wrap{
  overflow-y:scroll;
  height:auto;
}
.filter_music{
    transition: left .4s;
    width: 260px;
    display: inline-block;
    vertical-align:top;
    border-radius: 2px;
    border: 1px solid #E2E5E9;
    position: fixed;
    left: 0px;
    top: 90px;
    bottom: 0rem;
    z-index: 4;
    .filter_head{
      display:block;
      border-bottom: 1px solid #E2E5E9;
      padding-top:11px;
      line-height: 25px;
      h3{
        display:inline-block;
        padding-left: 24px;
        width: 50%;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      p{
        display:inline-block;
        width: 50%;
        text-align:right;
        padding-right: 5%;
        cursor:pointer;
      }
    }
  }
  .filter_table_content{
    display: inline-block;
    transition: all ease-in .2s;
    .table_head{
      line-height:40px;
      background: #F9FAFB;
      border-radius: 4px;
      border: 1px solid #E5E7EB;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      .head_total{
        margin-left: 1%;
      }
      .sort_tag{
        cursor:pointer;
        display:inline-block;
        line-height: 40px;
      }
      .sort_tag[selected]{
        color: #FF7B13;
      }
      .type_button{
        margin-right: 1%;
        border-radius: 2px;
      }
      .type_button[selected]{
        background:#FF7B13;
        color: #fff;
      }
    }
    .table_bottom{
      min-height: 600px;
      margin-top:10px;
    }
  }
  // mobile
@media only screen and (max-width: $screen-width-md) {
  .filter_music{
    //width: 35%;
  }
  .filter_table_content{
    //width: 62%;
    .table_head {
      h3 {
        width: 50%;
        font-size: 14px;
      }
      .table_head_tab{
        width: 50%;
        span{
          font-size: 12px;
        }
        span[disabled]{
          font-size: 12px;
        }
        
      }
    }
  }
}
</style>
